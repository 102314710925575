import React from "react";
import theme from "theme";
import { Theme, Text, Box, Strong, Section, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Explore Unlimited
			</title>
			<meta name={"description"} content={"ВАШ ШЛЯХ ДО НЕЗАБУТНІХ ПРИГОД І НЕЗАБУТНІХ ВРАЖЕНЬ ВІД ОРЕНДИ КВАДРОЦИКЛА."} />
			<meta property={"og:title"} content={"Explore Unlimited"} />
			<meta property={"og:description"} content={"ВАШ ШЛЯХ ДО НЕЗАБУТНІХ ПРИГОД І НЕЗАБУТНІХ ВРАЖЕНЬ ВІД ОРЕНДИ КВАДРОЦИКЛА."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6639d7741d712a00233396a9/images/2-5.jpg?v=2024-05-07T12:36:57.721Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6639d7741d712a00233396a9/images/2-5.jpg?v=2024-05-07T12:36:57.721Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6639d7741d712a00233396a9/images/2-5.jpg?v=2024-05-07T12:36:57.721Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6639d7741d712a00233396a9/images/2-5.jpg?v=2024-05-07T12:36:57.721Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6639d7741d712a00233396a9/images/2-5.jpg?v=2024-05-07T12:36:57.721Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6639d7741d712a00233396a9/images/2-5.jpg?v=2024-05-07T12:36:57.721Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6639d7741d712a00233396a9/images/2-5.jpg?v=2024-05-07T12:36:57.721Z"} />
		</Helmet>
		<Components.Header12>
			<Override slot="link1" />
			<Override slot="link" />
			<Override slot="link2" />
			<Override slot="link3" />
		</Components.Header12>
		<Section
			padding="70px 0 70px 0"
			sm-padding="40px 0 40px 0"
			background="linear-gradient(120deg,rgba(0,0,0,.2) 0%,rgba(16, 1, 1, 0.73) 100%),--color-dark url(https://uploads.quarkly.io/6639d7741d712a00233396a9/images/2-5.jpg?v=2024-05-07T12:36:57.721Z) center/cover"
			color="--light"
			font="--base"
		>
			<Override slot="SectionContent" sm-align-items="center" />
			<Box width="100%">
				<Text
					as="h1"
					margin="0 0 96px 0"
					text-align="center"
					letter-spacing="20px"
					text-transform="uppercase"
					font="--headline3"
					lg-font="200 42px/1.2 --fontFamily-sans"
					lg-letter-spacing="20px"
				>
					Explore Unlimited
				</Text>
			</Box>
			<Box
				display="flex"
				margin="-16px -16px -16px -16px"
				flex-wrap="wrap"
				width="100%"
				justify-content="space-between"
			>
				<Box
					display="flex"
					padding="16px 16px 16px 16px"
					width="25%"
					lg-width="50%"
					sm-width="100%"
				>
					<Box display="flex">
						<Text margin="0px" font="--lead" text-transform="uppercase">
							<Strong
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								ВАШ ШЛЯХ ДО НЕЗАБУТНІХ ПРИГОД І НЕЗАБУТНІХ ВРАЖЕНЬ ВІД ОРЕНДИ КВАДРОЦИКЛА.{" "}
							</Strong>
						</Text>
					</Box>
				</Box>
				<Box
					display="flex"
					padding="16px 16px 16px 16px"
					width="25%"
					lg-width="50%"
					sm-width="100%"
				>
					<Box display="flex">
						<Text margin="0px">
							Незалежно від того, чи є ви досвідченим позашляховиком, чи новачком, який прагне дослідити світ, ми пропонуємо ідеальне поєднання якості, сервісу та гострих відчуттів, яке робить кожну поїздку винятковою.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Description-9">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				sm-margin="0px 0px 30px 0px"
				sm-padding="0px 0px 0px 0px"
				align-items="flex-start"
				lg-align-items="center"
				flex-direction="column"
				lg-width="100%"
				lg-margin="0px 0px 0px 0px"
				padding="24px 24px 24px 24px"
				justify-content="center"
				display="flex"
				width="50%"
			>
				<Box>
					<Text
						margin="0px 0px 36px 0px"
						color="--darkL2"
						font="--headline2"
						lg-text-align="center"
						sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					>
						Про нас
					</Text>
				</Box>
				<Text margin="0px 0px 0px 0px" color="--grey" font="--lead" lg-text-align="center">
					В Explore Unlimited ми розуміємо поклик природи і готові допомогти вам відповісти на нього. Наші першокласні квадроцикли обслуговуються до досконалості, забезпечуючи безпечну і хвилюючу поїздку захоплюючими дух пейзажами. Наш доброзичливий і обізнаний персонал завжди готовий допомогти вам у всіх аспектах вашої пригоди, подбавши про те, щоб ви отримали незабутні та безтурботні враження.
				</Text>
			</Box>
			<Box
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="24px 24px 24px 24px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
			>
				<Box
					transform="translateY(0px)"
					hover-transform="translateY(-10px)"
					width="100%"
					height="auto"
					overflow-y="hidden"
					padding="0px 0px 100% 0px"
					overflow-x="hidden"
					position="relative"
					transition="transform 0.2s ease-in-out 0s"
				>
					<Image
						object-fit="cover"
						display="block"
						width="100%"
						right={0}
						src="https://uploads.quarkly.io/6639d7741d712a00233396a9/images/1-2.jpg?v=2024-05-07T12:36:57.704Z"
						position="absolute"
						top="auto"
						left={0}
						bottom="0px"
						min-height="100%"
						srcSet="https://smartuploads.quarkly.io/6639d7741d712a00233396a9/images/1-2.jpg?v=2024-05-07T12%3A36%3A57.704Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6639d7741d712a00233396a9/images/1-2.jpg?v=2024-05-07T12%3A36%3A57.704Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6639d7741d712a00233396a9/images/1-2.jpg?v=2024-05-07T12%3A36%3A57.704Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6639d7741d712a00233396a9/images/1-2.jpg?v=2024-05-07T12%3A36%3A57.704Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6639d7741d712a00233396a9/images/1-2.jpg?v=2024-05-07T12%3A36%3A57.704Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6639d7741d712a00233396a9/images/1-2.jpg?v=2024-05-07T12%3A36%3A57.704Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6639d7741d712a00233396a9/images/1-2.jpg?v=2024-05-07T12%3A36%3A57.704Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Description-9">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="24px 24px 24px 24px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
			>
				<Box
					transform="translateY(0px)"
					hover-transform="translateY(-10px)"
					width="100%"
					height="auto"
					overflow-y="hidden"
					padding="0px 0px 100% 0px"
					overflow-x="hidden"
					position="relative"
					transition="transform 0.2s ease-in-out 0s"
				>
					<Image
						object-fit="cover"
						display="block"
						width="100%"
						right={0}
						src="https://uploads.quarkly.io/6639d7741d712a00233396a9/images/1-3.jpg?v=2024-05-07T12:36:57.697Z"
						position="absolute"
						top="auto"
						left={0}
						bottom="0px"
						min-height="100%"
						srcSet="https://smartuploads.quarkly.io/6639d7741d712a00233396a9/images/1-3.jpg?v=2024-05-07T12%3A36%3A57.697Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6639d7741d712a00233396a9/images/1-3.jpg?v=2024-05-07T12%3A36%3A57.697Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6639d7741d712a00233396a9/images/1-3.jpg?v=2024-05-07T12%3A36%3A57.697Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6639d7741d712a00233396a9/images/1-3.jpg?v=2024-05-07T12%3A36%3A57.697Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6639d7741d712a00233396a9/images/1-3.jpg?v=2024-05-07T12%3A36%3A57.697Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6639d7741d712a00233396a9/images/1-3.jpg?v=2024-05-07T12%3A36%3A57.697Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6639d7741d712a00233396a9/images/1-3.jpg?v=2024-05-07T12%3A36%3A57.697Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
			<Box
				sm-margin="0px 0px 30px 0px"
				sm-padding="0px 0px 0px 0px"
				align-items="flex-start"
				lg-align-items="center"
				flex-direction="column"
				lg-width="100%"
				lg-margin="0px 0px 0px 0px"
				padding="24px 24px 24px 24px"
				justify-content="center"
				display="flex"
				width="50%"
			>
				<Box>
					<Text
						margin="0px 0px 36px 0px"
						color="--darkL2"
						font="--headline2"
						lg-text-align="center"
						sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					>
						Переваги вибору Explore Unlimited
					</Text>
				</Box>
				<Text margin="0px 0px 0px 0px" color="--grey" font="--lead" lg-text-align="center">
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					/>
					<Strong>
						Квадроцикли преміум-класу:
					</Strong>
					{" "}у нашому автопарку представлені найновіші та найбільш обслуговувані квадроцикли на ринку.{"\n"}
					<br />
					<br />
					<Strong>
						Персоналізовані маршрути:
					</Strong>
					{" "}створіть для своєї пригоди індивідуальні маршрути, що відповідають вашому рівню навичок та інтересам.{"\n"}
					<br />
					<br />
					<Strong>
						Безпека понад усе:
					</Strong>
					{" "}ми приділяємо пріоритетну увагу вашій безпеці завдяки комплексним інструктажам і першокласному обладнанню.{"\n"}
					<br />
					<br />
					<Strong>
						Доступні пригоди:{" "}
					</Strong>
					конкурентоспроможні ціни та прозора вартість не означають жодних сюрпризів - лише чисте задоволення.
				</Text>
			</Box>
		</Section>
		<Components.Contacts />
		<Components.Footer12>
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link2" />
			<Override slot="link3" />
		</Components.Footer12>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"658eb1148e2e8e00217540ff"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});